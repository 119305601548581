.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.canvas {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: black;
  overflow: hidden;
  cursor: url('data:image/x-icon;base64,AAACAAEAICACAAAAAAAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAgAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAA/Pn1AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAYAAAAF+AAABBAAAAQgAAAEQAAABIAAAAUAAAAGAAAABAAAAAAAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////7////+f////gH///4D///+B////g////4f///+P////n////7/////////8='), auto;
}

.lampconfig {
  background-color: #ffffff;
  display: inline-block;
  z-index: 2;
  border-radius: .5em;
  padding: .5em;
  margin: .5em;
  position: relative;
}

.lampcontainer {
  mix-blend-mode: screen;
}

.lampconfig > h6 {
  text-align: right;
}

.colorPicker {
  margin: auto;
}

.settings {
  position: absolute;
}
